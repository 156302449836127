"use client";

import React from "react";
import Button from "../button/Button";
import { useOrderNowMenu } from "@/context/OrderNowMenuContext";

interface OpenLocationsButtonProps {
  label?: string;
}

const OpenLocationsButton: React.FC<OpenLocationsButtonProps> = ({
  label = "Order Now",
}) => {
  const { toggleMenu } = useOrderNowMenu();
  return <Button label={label} variant="elevated" onClick={toggleMenu} />;
};

export default OpenLocationsButton;
