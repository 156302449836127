import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/og-image.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/about/ScrollToSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/button/Button.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/locations/OpenLocationsButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/main/Main.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/section/Section.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/container/Container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[location]/LocationPage.module.scss");
